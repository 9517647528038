$orange: #e26329;
$white: #fff;
$darkgrey: #403f3d;
$lightgrey: #d3d3d3;
$midgrey: #f7f7f9;
$plaingrey: #eeeeee;
$deliverygrey: #EFEFF3;
$lightergrey: #f8f8fa;
$bordergrey: #0c0b0b;
$textgrey: #818181;
$red: #e22929;
$black: #000;
$yellow: #eda310;
$green: #3FDF82;
$arrowGrey: #8C8C8C;
$lightOrange: #FEF8F3;
$presold: #FCE8DF;
$lightgreen: #7BC292;
$lightred : #EE7F81;
$pleasecall: #FEF4EA;
$presoldOrange: #e26329;
$midgreybutton: #949393;
// $fontJosefinSans: "Josefin Sans";
// $fontOswald: "Oswald";
$fontRoboto: "Roboto", sans-serif;
$paygrey: #F8F8F8;
$paygreyText: #969B9F;
$midgreybutton: #949393;
$thin: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$fontSizeExtraExtraSmall: 10px;
$fontSizeExtraSmall: 14px;
$fontSizeSmallMedium: 15px;
$fontSizeSmall: 17px;
$fontSizeMedium: 22px;
$fontSizeSemiLarge: 26px;
$fontSizeMediumLarge: 32px;
$fontSizeLarge: 36px;

